/**
 * @copyright 2023-2024 Chris Zuber <admin@kernvalley.us>
 * @see https://wicg.github.io/sanitizer-api/#default-configuration-dictionary
 */
import { HTML as namespace } from '@aegisjsproject/sanitizer/namespaces.js';
import { normalizeAttr, normalizeElement } from '@aegisjsproject/sanitizer/config-utils.js';
import { attributes as globalAttrs } from '@aegisjsproject/sanitizer/config/global.js';
export const comments = true;

export const dataAttributes = true;

/**
 * Tags listed on MDN, + a few deprecated / experimental ones
 * Selector: `#content table td > a[href^="/en-US/docs/Web/HTML/Element/"] > code`
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element
 */
export const elements = Object.freeze([
	'html', 'head', 'link', 'meta', 'body', 'address', 'article', 'aside', 'footer',
	'header', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hgroup', 'main', 'nav', 'section',
	'search', 'blockquote', 'cite', 'div', 'dd', 'dt', 'dl', 'figcaption', 'figure',
	'hr', 'li', 'ol', 'ul', 'menu', 'p', 'pre', 'a', 'abbr', 'b', 'bdi', 'bdo', 'br',
	'code', 'data', 'dfn', 'em', 'i', 'kbd', 'mark', 'q', 'rp', 'ruby', 'rt', 's',
	'del', 'ins', 'samp', 'small', 'span', 'strong', 'sub', 'sup', 'time', 'u',
	'var', 'wbr', 'area', 'audio', 'img', 'map', 'track', 'video', 'picture',
	'source', 'canvas', 'caption', 'col', 'colgroup', 'table', 'tbody', 'tr', 'td',
	'tfoot', 'th', 'thead', 'button', 'datalist', 'option', 'fieldset', 'label',
	'form', 'input', 'legend', 'meter', 'optgroup', 'select', 'output', 'progress',
	'textarea', 'details', 'summary', 'dialog', 'slot', 'template', 'dir',
	'strike', 'selectmenu', 'center',
].map(el => normalizeElement(el, namespace)));

export const attributes = Object.freeze([
	'abbr',
	'accept',
	'accept-charset',
	// 'accesskey', //global
	// 'action',
	'align',
	'alink',
	'allow',
	'allowfullscreen',
	'alt',
	'anchor',
	'archive',
	'as',
	'async',
	// 'autocapitalize', //global
	'autocomplete',
	'autocorrect',
	// 'autofocus', //global
	'autopictureinpicture',
	'autoplay',
	'axis',
	'background',
	'behavior',
	// 'bgcolor',
	'border',
	'bordercolor',
	'capture',
	'cellpadding',
	'cellspacing',
	'challenge',
	'char',
	'charoff',
	'charset',
	'checked',
	'cite',
	// 'class', //global
	'classid',
	'clear',
	'code',
	// 'codebase',
	'codetype',
	'color',
	'cols',
	'colspan',
	'compact',
	'content',
	// 'contenteditable', //global
	'controls',
	'controlslist',
	'conversiondestination',
	'coords',
	'crossorigin',
	'csp',
	'data',
	'datetime',
	'declare',
	'decoding',
	'default',
	'defer',
	// 'dir', //global
	'direction',
	'dirname',
	'disabled',
	'disablepictureinpicture',
	'disableremoteplayback',
	'disallowdocumentaccess',
	'download',
	// 'draggable', //global
	'elementtiming',
	'enctype',
	'end',
	// 'enterkeyhint', //global
	// 'event',
	// 'exportparts', //global
	// 'face',
	'for',
	'form',
	// 'formaction',
	'formenctype',
	'formmethod',
	'formnovalidate',
	'formtarget',
	// 'frame',
	'frameborder',
	'headers',
	'height',
	// 'hidden', //global
	'high',
	'href',
	'hreflang',
	'hreftranslate',
	'hspace',
	// 'http-equiv',
	// 'id', //global
	'imagesizes',
	'imagesrcset',
	'importance',
	'impressiondata',
	'impressionexpiry',
	'incremental',
	// 'inert', //global
	// 'inputmode', //global
	'integrity',
	'invisible',
	'invoketarget',
	'invokeaction',
	'is',
	'ismap',
	'keytype',
	'kind',
	'label',
	// 'lang', //global
	'language',
	'latencyhint',
	'leftmargin',
	'link',
	'list',
	'loading',
	'longdesc',
	'loop',
	'low',
	'lowsrc',
	'manifest',
	'marginheight',
	'marginwidth',
	'max',
	'maxlength',
	'mayscript',
	'media',
	'method',
	'min',
	'minlength',
	'multiple',
	'muted',
	'name',
	'nohref',
	'nomodule',
	// 'nonce',
	'noresize',
	'noshade',
	'novalidate',
	'nowrap',
	'object',
	'open',
	'optimum',
	// 'part', //global
	'pattern',
	'ping',
	'placeholder',
	'playsinline',
	'policy',
	// 'popover', //global
	'popovertarget',
	'popovertargetaction',
	'poster',
	'preload',
	'pseudo',
	'readonly',
	'referrerpolicy',
	'rel',
	'reportingorigin',
	'required',
	'resources',
	'rev',
	'reversed',
	'role',
	'rows',
	'rowspan',
	'rules',
	'sandbox',
	'scheme',
	'scope',
	'scopes',
	'scrollamount',
	'scrolldelay',
	'scrolling',
	'select',
	'selected',
	'shadowrootmode',
	'shadowrootdelegatesfocus',
	'shape',
	'size',
	'sizes',
	// 'slot', //global
	'span',
	// 'spellcheck',  //global
	'src',
	'srcdoc',
	'srclang',
	'srcset',
	'standby',
	'start',
	'step',
	// 'style',
	'summary',
	// 'tabindex',//global
	'target',
	'text',
	// 'title', //global
	'topmargin',
	// 'translate', //global
	'truespeed',
	'trusttoken',
	'type',
	'usemap',
	'valign',
	'value',
	'valuetype',
	'version',
	// 'virtualkeyboardpolicy', //global
	'vlink',
	'vspace',
	'webkitdirectory',
	'width',
	'wrap',
].map(attr => normalizeAttr(attr)).concat(globalAttrs));

export const sanitizer = Object.freeze({ comments, elements, attributes, dataAttributes });
